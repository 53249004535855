import React from 'react';
import cx from 'classnames';

import { isOptionalCategory } from '../../../utilities/optionalCategories';
import type { Photo } from '../../../utilities/Types/photo.types';
import type { CategoryType, ImageCategoryType } from '../../../utilities/Types/vehiclePhotosCategories.types';
import { HELP_PANEL_DAMAGE_KINDS } from '../../../utilities/vehiclePhotosCategories';
import { PhotoTips } from '../Help/HelpModal/PhotoTips/PhotoTips';
import { OnboardingMessage } from '../OnboardingMessage/OnboardingMessage';
import { Skip } from '../Skip/Skip';
import { Tooltip } from '../Tooltip/Tooltip';

import { SkipInfo } from './Overlay.types';

import styles from './Overlay.module.scss';

export type HelpButtonType = typeof HELP_PANEL_DAMAGE_KINDS[number];

type CategoryLabelProps = {
  categoryItem?: CategoryType;
  currentCategory: CategoryType[]
}

type CategoryOnboardingMessageProps = {
  categoryImages: Photo[];
  categoryItem: CategoryType,
  showDamageLocationOverlay: boolean;
  timings: number[];
  uploadingPhoto: Record<never, never> | null;
}

type SkipOrHelpButtonProps = {
  disableUI: boolean;
  kind: HelpButtonType;
  skipInfo?: Partial<SkipInfo> | null;
}

type CaptureButtonProps = {
  captureImage: () => void;
  category: ImageCategoryType;
  categoryDone: boolean;
  disabled: boolean;
  flash: boolean;
  showHintTooltip: boolean;
  skipInfo?: SkipInfo | null;
}

export const CategoryLabel = ({ categoryItem, currentCategory }: CategoryLabelProps) => {
  if (!categoryItem) {
    return null;
  }

  const { category, kind } = categoryItem;
  const currentIndex = currentCategory.findIndex((v) => v.kind === kind);
  let { description } = currentCategory[currentIndex];

  if (!isOptionalCategory(category)) {
    description = `${currentIndex + 1}/${currentCategory.length} ${description}`;
  }

  return (
    <div className={styles.categoryLabel}>
      {description}
    </div>
  );
};

export const CategoryOnboardingMessage = ({
  categoryImages, categoryItem, showDamageLocationOverlay, timings, uploadingPhoto,
}: CategoryOnboardingMessageProps) => {
  const [startTime, endTime] = timings || [0, 0];
  const categoryHasImage = categoryImages.some((image) => image.kind === categoryItem.kind);
  const showOnboardingMessage = (categoryItem.onboardingMessage
  && (!categoryHasImage || categoryItem.onboardingForceShow)
  && !showDamageLocationOverlay) as boolean;

  if (categoryItem.onboardingMessage) {
    return (
      <OnboardingMessage
        delay={Math.round((endTime - startTime) * 1000)}
        isPersistent={categoryItem.onboardingMessageShouldPersist}
        isVisible={!uploadingPhoto}
        show={showOnboardingMessage}
      >
        {categoryItem.onboardingMessage}
      </OnboardingMessage>
    );
  }

  return null;
};

export const SkipOrHelpButton = ({ disableUI, kind, skipInfo }: SkipOrHelpButtonProps) => {
  if (HELP_PANEL_DAMAGE_KINDS.includes(kind)) {
    return (
      <PhotoTips kind={kind} />
    );
  }

  if (skipInfo) {
    return (
      <Skip {...skipInfo as SkipInfo} className={cx(styles.skip, { [styles.disabled]: disableUI })} />
    );
  }

  return null;
};

export const CaptureButton = ({
  captureImage, category, categoryDone, disabled, flash, showHintTooltip, skipInfo,
}: CaptureButtonProps) => (
  <>
    <button
      className={
        cx(
          styles.photoButton,
          {
            [styles.activeButton]: flash,
            [styles.disabled]: (skipInfo?.category === 'done' && !isOptionalCategory(category))
            || (categoryDone && !isOptionalCategory(category)) || disabled,
          },
        )
      }
      onClick={captureImage}
      type="button"
    />
    <Tooltip className={styles.skipTooltip} showTooltip={showHintTooltip}>
      To start taking photos, tap here.
    </Tooltip>
  </>
);
