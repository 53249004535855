import React, { Children, useContext, useEffect } from 'react';

import { MDText } from 'i18n-react';

import { Content } from '@motorway/motorway-storybook-cra';
import { InfoBox } from '@motorway/mw-highway-code/alpha';

import camera from '../../../assets/images/messagingCard/camera.svg';
import carKey from '../../../assets/images/messagingCard/car-key.svg';
import { GA_EVENT_ACTIONS, GA_EVENTS } from '../../../utilities/analytics';
import { PHOTOS_HUB_EVENTS } from '../../../utilities/analytics/events/photosHub/photosHubEvents';
import { useProgress } from '../../../utilities/hooks/useProgress';
import { VEHICLE_PHOTO_CATEGORY_ORDER, VEHICLE_PHOTO_CATEGORY_PATH } from '../../../utilities/vehiclePhotosCategories';
import { Context } from '../../context/context';
import { usePhotosContext } from '../../context/photos';
import MessagingCard from '../MessagingCard/MessagingCard';
import { VehicleCenterHeader } from '../VehicleCenterHeader/VehicleCenterHeader';

import HubFooter from './HubFooter/HubFooter';
import Section from './Section/Section';
import { sectionIcon } from './Section/Section.helper';
import { checkIfPhotoUploading, getDescription, PHOTOS_HUB_GA_EVENT_LABEL } from './PhotosHub.helpers';
import { useAiCatPageGaLoader, useHubPageLoaderGaEvent } from './PhotosHub.hooks';
import LocalTexts from './VehiclePhotos.json';

import styles from './PhotosHub.module.scss';

const LocalT = new MDText(LocalTexts);

const PhotosHub = ({ uploadingQueue = {} }) => {
  const { sortedPhotos } = usePhotosContext();
  const { offer, seller: { firstName } } = useContext(Context);

  const sectionsProgress = useProgress({ uploadingQueue });

  const completed = Object.values(sectionsProgress).every((category) => category.isDone);
  const initiated = Object.values(sectionsProgress).every((category) => category.current === 0);

  const needToUpload = Object.values(sectionsProgress).some((category) => category.outStandingUploads > 0);
  const needRetaking = Object.values(sectionsProgress).some((category) => category.outStandingImageAssessment > 0);
  const totalOutStandingImageAssessments = Object.values(sectionsProgress).reduce(
    (acc, category) => acc + category.outStandingImageAssessment,
    0,
  );

  const fireAiImageAssistGaEvent = totalOutStandingImageAssessments > 0;

  const messagingCardState = completed ? 'completed' : 'initiated';
  const messagingCardImage = completed ? <img alt="car key" className={styles.carKey} src={carKey} /> : <img alt="polaroid camera" className={styles.polaroidCamera} src={camera} />;

  const showMessagingCardSection = (initiated || completed) && !needRetaking && !needToUpload;

  const { BUTTON } = GA_EVENT_ACTIONS;
  const { AI_IMAGE_ASSIST, CATEGORY, SECTION_BUTTON_LABEL } = PHOTOS_HUB_GA_EVENT_LABEL;

  const text = {
    attentionDescription: (total, category, type) => LocalT.translate(`vehiclePhotos.vehiclePhotosHub.sections.${category}.${type}.${total > 1 ? 'plural' : 'singular'}`, { total }),
    defaultDescription: (current, total, category) => LocalT.translate(`vehiclePhotos.vehiclePhotosHub.sections.${category}.progress`, { progress: current, total }),
    defaultTitle: (category) => LocalT.translate(`vehiclePhotos.vehiclePhotosHub.sections.${category}.title`),
    errorsDesc: LocalT.translate('vehiclePhotos.vehiclePhotosHub.errors.desc'),
    errorsTitle: LocalT.translate('vehiclePhotos.vehiclePhotosHub.errors.title'),
    estimatedSalePrice: LocalT.translate('vehiclePhotos.vehiclePhotosHub.header.estimatedSalePrice'),
    headerDescription: LocalT.translate('vehiclePhotos.vehiclePhotosHub.header.description'),
    messagingCardDescription: LocalT.translate(`vehiclePhotos.vehiclePhotosHub.messagingCard.${messagingCardState}.description`),
    messagingCardHeading: LocalT.translate(`vehiclePhotos.vehiclePhotosHub.messagingCard.${messagingCardState}.heading`, { sellerName: firstName }),
  };

  useEffect(() => {
    PHOTOS_HUB_EVENTS.PHOTOS_HUB_PAGE_LOAD(JSON.stringify(sectionsProgress));
  }, [sectionsProgress]);

  useHubPageLoaderGaEvent({ completed, gaEventLabel: PHOTOS_HUB_GA_EVENT_LABEL, initiated });
  useAiCatPageGaLoader({ fireAiImageAssistGaEvent, totalOutStandingImageAssessments });

  return (
    <>
      <div className={styles.main}>
        <VehicleCenterHeader
          amount={offer.sellerWillAcceptPrice || offer.priceHigh}
          amountLabel={text.estimatedSalePrice}
          className={styles.photosHubHeader}
          description={!(initiated || completed) && text.headerDescription}
        />
        <Content>
          <div className={styles.messagingCardSection}>
            {needToUpload && (
              <InfoBox
                content={text.errorsDesc}
                heading={text.errorsTitle}
                variant="error"
              />
            )}
            {showMessagingCardSection && (
              <MessagingCard
                description={text.messagingCardDescription}
                heading={text.messagingCardHeading}
                image={messagingCardImage}
              />
            )}
          </div>
          {Children.toArray(VEHICLE_PHOTO_CATEGORY_ORDER.map((category) => {
            const {
              current,
              isDone,
              outStandingImageAssessment,
              outStandingRetake,
              outStandingUploads,
              total,
            } = sectionsProgress[category];
            const { defaultTitle } = text;

            const title = defaultTitle(category);
            const isPhotosUploading = checkIfPhotoUploading(sortedPhotos[category], uploadingQueue);
            const { description, hasError } = getDescription({
              category,
              current,
              isPhotosUploading,
              outStandingImageAssessment,
              outStandingRetake,
              outStandingUploads,
              text,
              total,
            });

            const icon = sectionIcon(isDone, hasError, isPhotosUploading);

            const redirectPath = VEHICLE_PHOTO_CATEGORY_PATH[category].name;
            const sectionButtonLabel = LocalT.translate(SECTION_BUTTON_LABEL, { current, title, total });
            const aiSectionButtonLabel = LocalT.translate(AI_IMAGE_ASSIST.SECTION_BUTTON_LABEL, { category: title });
            const redirectHandler = () => {
              GA_EVENTS.DEFAULT(BUTTON, CATEGORY, sectionButtonLabel);
              PHOTOS_HUB_EVENTS.PHOTOS_HUB_SECTION_CLICK(category);

              GA_EVENTS.IMAGE_ASSESSMENT({
                eventAction: GA_EVENT_ACTIONS.BUTTON,
                eventCategory: AI_IMAGE_ASSIST.CATEGORY,
                eventLabel: aiSectionButtonLabel,
                eventValue: outStandingImageAssessment,
              });
            };
            return (
              <Section
                {...sectionsProgress[category]}
                className={styles.section}
                description={description}
                hasError={hasError}
                icon={icon}
                redirectHandler={redirectHandler}
                redirectPath={redirectPath}
                title={title}
              />
            );
          }))}
        </Content>
      </div>
      <HubFooter
        isUploading={Object.keys(uploadingQueue).length > 0}
        show={completed}
        toUpload={needToUpload}
        totalOutStandingImageAssessments={totalOutStandingImageAssessments}
      />
    </>
  );
};

export default PhotosHub;
