import React, { useEffect } from 'react';

import { MDText } from 'i18n-react';

import { TickFilledIcon } from '@motorway/motorway-storybook-cra';

import { GENERAL_EVENTS } from '../../../utilities/analytics/events/general/generalEvents';
import Modal from '../Modal/Modal';

import LocalTexts from './LandscapeWarning.json';

import styles from './LandscapeWarning.module.scss';

const LocalT = new MDText(LocalTexts);

export const LandscapeWarning = () => {
  const text = {
    description: LocalT.translate('landscapeWarning.description'),
    heading: LocalT.translate('landscapeWarning.heading'),
  } as Record<string, string>;

  useEffect(() => {
    window.dataLayer.push({
      event: 'Pageview',
      pageTitle: 'Rotate device screen view',
    });

    GENERAL_EVENTS.ROTATE_DEVICE_SHOWN();

    return () => {
      window.dataLayer.push({
        event: 'Pageview',
        pageTitle: 'Rotate device screen view removed',
      });
    };
  }, []);

  return (
    <Modal>
      <div className={styles.component}>
        <section className={styles.imageBlock}>
          <div className={styles.phoneFrame}>
            <div className={styles.phoneMask}>
              <div className={styles.carImage} />
            </div>
          </div>
          <TickFilledIcon className={styles.greenTick} height={52} width={52} />
        </section>
        <section className={styles.textBlock}>
          <h1>{text.heading}</h1>
          <p>{text.description}</p>
        </section>
      </div>
    </Modal>
  );
};

export default LandscapeWarning;
