import { GENERIC_ANALYTICS_EVENTS } from '../../analyticsGenericEvents';
import { EVENT_CATEGORY } from '../events';

export const CAMERA_PAGE_EVENTS = {
  EXIT_BUTTON_CLICKED: (kind: string) => GENERIC_ANALYTICS_EVENTS.CLICK_EVENT({
    category: EVENT_CATEGORY,
    label: kind,
    name: 'camera_exit',
  }),
  HELP_BUTTON_CLICKED: (kind: string) => GENERIC_ANALYTICS_EVENTS.CLICK_EVENT({
    category: EVENT_CATEGORY,
    label: kind,
    name: 'camera_help',
  }),
} as const;
