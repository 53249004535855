import React, { useEffect, useRef, useState } from 'react';
import cx from 'classnames';

import { MDText } from 'i18n-react';

import { InputFile } from '@motorway/mw-highway-code/pre-alpha';

import { GA_EVENT_ACTIONS, GA_EVENTS } from '../../../../../../utilities/analytics';
import { CATEGORY_PAGE_EVENTS } from '../../../../../../utilities/analytics/events/categoryPage/categoryPageEvents';
import { isSellerSupplied, titleCaseFirstLetter } from '../../../../../../utilities/helpers';
import { usePhotosContext } from '../../../../../context/photos';
import { withBreakpointContext } from '../../../../Breakpoints/Breakpoints';
import { CATEGORY_PAGE_GA_EVENT_LABEL } from '../../CategoryPage.helpers';
import { renderContent } from '../PhotoBlock.helpers';
import { useImageToURL } from '../PhotoBlock.hooks';

import { LockedMessageOverlay } from './LockedMessageOverlay/LockedMessageOverlay';
import { Subtitle } from './Subtitle/Subtitle';
import type { PhotoItemProps, SubtitleProps } from './PhotoItem.types';

import styles from './PhotoItem.module.scss';

const LocalT = new MDText({});

const PhotoItem = ({
  faultDetected,
  handleChange = () => undefined,
  id,
  imageAssessmentPending,
  imageAssessmentStatus,
  imageAssessmentSuccessful,
  img = null,
  inProgress,
  isApproved = false,
  isCurrent = false,
  isDamage = false,
  isRetake = false,
  kind,
  submitPhoto = () => undefined,
  title = null,
  uploadingProgress = null,
}: PhotoItemProps) => {
  const { updatePhoto } = usePhotosContext();

  const imageURL = useRef<string>();
  const clearOverlayTimeout = useRef<NodeJS.Timeout>();
  const inputFileRef = useRef<HTMLInputElement>(null);

  const [newSubtitle, setNewSubtitle] = useState<SubtitleProps>();
  const [showNewContent, setShowNewContent] = useState(false);

  const [showLockedMessage, setShowLockedMessage] = useState(false);

  const handleShowLockedMessage = () => {
    const { AI_IMAGE_ASSIST } = CATEGORY_PAGE_GA_EVENT_LABEL;
    const { CATEGORY, LOCKED_ICON_LABEL } = AI_IMAGE_ASSIST;
    setShowLockedMessage(true);
    GA_EVENTS.IMAGE_ASSESSMENT({
      eventAction: GA_EVENT_ACTIONS.BUTTON,
      eventCategory: CATEGORY,
      eventLabel: LocalT.translate(LOCKED_ICON_LABEL, ({ kind: title })),
      eventValue: imageAssessmentStatus,
    });
  };

  const animationMs = 1000;

  const { imageToURL } = useImageToURL(imageURL);

  useEffect(() => {
    if (imageAssessmentPending) {
      clearOverlayTimeout.current = setTimeout(() => {
        updatePhoto(id, { autoAssessStatus: 'pending' });
      }, 30000); // After 30 seconds, override autoAssessStatus
    }

    return () => {
      clearTimeout(clearOverlayTimeout.current);
    };
  }, [id, imageAssessmentPending, updatePhoto]);

  useEffect(() => {
    if (imageAssessmentStatus && faultDetected) {
      CATEGORY_PAGE_EVENTS.CATEGORY_PAGE_IMAGE_ASSIST_LOAD(imageAssessmentStatus);
    }
  }, [imageAssessmentStatus, faultDetected]);

  const contentObj = renderContent({
    faultDetected,
    handleChange,
    handleShowLockedMessage,
    imageAssessmentPending,
    imageAssessmentStatus,
    imageAssessmentSuccessful,
    inProgress,
    isApproved,
    isBrokenImage: img?.isBrokenImage,
    isCurrent,
    isDamage,
    isRetake,
    isSellerSupplied: isSellerSupplied(img?.status),
    isUploaded: img?.uploaded,
    isUploading: Number.isInteger(uploadingProgress),
    photo: img,
    submitPhoto,
  });

  const { subtitle } = contentObj;

  useEffect(() => {
    let animationUpdate: NodeJS.Timeout;
    const updatedSubtitle = subtitle || {};

    const parent = inputFileRef.current?.firstChild?.firstChild as HTMLDivElement;
    const mainTitle = parent?.children[2]?.children[0]?.children[0];
    if (!newSubtitle) {
      setNewSubtitle(updatedSubtitle);
      setShowNewContent(true);
    } else {
      setShowNewContent(false);
      animationUpdate = setTimeout(() => {
        if (!updatedSubtitle) {
          mainTitle?.classList?.add(styles.slideDownDone);
        }
        setNewSubtitle(updatedSubtitle);
        setShowNewContent(true);
      }, animationMs);
    }

    return () => {
      clearTimeout(animationUpdate);
      mainTitle?.classList?.remove?.(styles.slideDownDone);
    };
  }, [subtitle?.subtitleText]);

  useEffect(() => {
    let animationUpdate: NodeJS.Timeout;

    const parent = inputFileRef.current?.firstChild?.firstChild as HTMLDivElement;
    const button = parent?.children[2]?.children[1];
    if (button) {
      button.classList.add(styles.button);
      animationUpdate = setTimeout(() => {
        button.classList.toggle(styles.buttonFadeIn, showNewContent);
        button.classList.toggle(styles.buttonFadeOut, !showNewContent);
      }, animationMs);
    }

    return () => {
      clearTimeout(animationUpdate);
      button?.classList?.remove?.(styles.button, styles.buttonFadeIn, styles.buttonFadeOut);
    };
  }, [showNewContent]);

  useEffect(() => {
    let disableShowLockedMessage: NodeJS.Timeout;
    const photoInputDescription = inputFileRef.current?.children[1]?.children[2];
    if (photoInputDescription && showLockedMessage) {
      photoInputDescription.classList.add(styles.descriptionFadeInFadeOut);
      disableShowLockedMessage = setTimeout(() => {
        setShowLockedMessage(false);
      }, 4600);
    }

    return () => {
      clearTimeout(disableShowLockedMessage);
      photoInputDescription?.classList?.remove?.(styles.descriptionFadeInFadeOut);
    };
  }, [showLockedMessage]);

  useEffect(() => {
    const parent = inputFileRef.current?.firstChild?.firstChild as HTMLDivElement;
    const mainTitle = parent?.children[2]?.children[0]?.children[0];
    const subtitleText = parent?.children[2]?.children[0]?.children[1];

    const showSlideDown = (subtitleText && mainTitle) && imageAssessmentSuccessful;
    const showSlideUp = (subtitleText && mainTitle) && isApproved;

    if (showSlideDown) {
      mainTitle.classList.add(styles.slideDown);
      subtitleText.classList.add(styles.slideDownFadeOut);
    }

    if (showSlideUp) {
      mainTitle.classList.add(styles.slideUp);
      subtitleText.classList.add(styles.slideUpFadeIn);
    }

    return () => {
      mainTitle?.classList?.remove?.(styles.slideDown, styles.slideUp);
      subtitleText?.classList?.remove?.(styles.slideDownFadeOut, styles.slideUpFadeIn);
    };
  }, [imageAssessmentSuccessful, isApproved]);

  const getTitle = () => {
    if (!isDamage || !img?.damageMeta?.panelType) {
      return title;
    }
    const damagePanelTitle = img?.damageMeta?.panelType.split('_').join(' ');

    return titleCaseFirstLetter(damagePanelTitle);
  };

  return (
    <div ref={inputFileRef}
      className={cx(styles.container, {
        [styles.skipped]: img?.skipped,
        [styles.noDescClick]: contentObj?.buttonProps?.onClick,
      })}>
      {showLockedMessage && (<LockedMessageOverlay/>)}
      <InputFile
        {...{ kind }}
        {...contentObj as any}
        id={`${img?.id ?? kind}`}
        label={`${kind}-${title}`}
        photoUrl={!isRetake ? imageToURL(img) : undefined}
        subtitle={(
          <Subtitle
            className={newSubtitle?.className}
            icon={newSubtitle?.icon ?? null}
            isApproved={isApproved}
            showNewContent={showNewContent}
            subtitleText={newSubtitle?.subtitleText ?? ''}
          />
        )}
        title={getTitle()}
      />
    </div>
  );
};

export default withBreakpointContext(PhotoItem);
