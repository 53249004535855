import { GENERIC_ANALYTICS_EVENTS } from '../../analyticsGenericEvents';
import { EVENT_CATEGORY } from '../events';

export const PHOTOS_HUB_EVENTS = {
  PHOTOS_HUB_PAGE_LOAD: (progress: string) => GENERIC_ANALYTICS_EVENTS.SECTION_LOAD_EVENT({
    category: EVENT_CATEGORY,
    label: progress,
    name: 'photos_hub',
  }),
  PHOTOS_HUB_SECTION_CLICK: (category: string) => GENERIC_ANALYTICS_EVENTS.CLICK_EVENT({
    category: EVENT_CATEGORY,
    label: category,
    name: 'photos_section',
  }),
  PHOTOS_HUB_SUBMIT_BUTTON_CLICK: () => GENERIC_ANALYTICS_EVENTS.CLICK_EVENT({
    category: EVENT_CATEGORY,
    name: 'Photos hub submit button',
  }),
} as const;
