import { GENERIC_ANALYTICS_EVENTS } from '../../analyticsGenericEvents';
import { EVENT_CATEGORY } from '../events';

export const ONBOARDING_EVENTS = {
  DENY_CAMERA_PERMISSION_CLICK: () => GENERIC_ANALYTICS_EVENTS.CLICK_EVENT({
    category: EVENT_CATEGORY,
    name: 'enable_camera_cancel',
  }),
  ENABLE_CAMERA_CLICK: () => GENERIC_ANALYTICS_EVENTS.CLICK_EVENT({
    category: EVENT_CATEGORY,
    name: 'enable_camera',
  }),
  ONBOARDING_PAGE_LOAD: (screenNumber: number) => GENERIC_ANALYTICS_EVENTS.SECTION_LOAD_EVENT({
    category: EVENT_CATEGORY,
    label: screenNumber.toString(),
    name: 'onboarding_screen',
  }),
  REENTRY_ENABLE_CAMERA_CLICK: () => GENERIC_ANALYTICS_EVENTS.CLICK_EVENT({
    category: EVENT_CATEGORY,
    name: 'reentry_enable_camera',
  }),
} as const;
