import React, { useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { MDText } from 'i18n-react';

import { ToastStatuses, useToast } from '@motorway/motorway-storybook-cra';

import { CATEGORY_PAGE_EVENTS } from '../../../../../utilities/analytics/events/categoryPage/categoryPageEvents';
import { isRetake as retakeStatuses } from '../../../../../utilities/helpers';
import { useFeatureFlags, useIsStatusRestricted } from '../../../../../utilities/hooks';
import type { DamageCategoryType } from '../../../../../utilities/Types/category.types';
import type { ContextValueTypes } from '../../../../../utilities/Types/contextTypes';
import type { Photo } from '../../../../../utilities/Types/photo.types';
import type { Stats, UploadingQueue } from '../../../../../utilities/Types/Types';
import type { ImageCategoryType, KindType } from '../../../../../utilities/Types/vehiclePhotosCategories.types';
import { IMAGE_CATEGORIES, VEHICLE_PHOTOS_CATEGORY_KEY } from '../../../../../utilities/vehiclePhotosCategories';
import { Context } from '../../../../context/context';
import { usePhotosContext } from '../../../../context/photos';
import { useSocket } from '../../../../context/Socket/Socket';
import { IMAGE_ASSESSMENTS_STATUSES } from '../../../../context/Socket/Socket.helpers';
import type { Assessment, ImageAssessmentsStatuses } from '../../../../context/Socket/Socket.types';
import { triggerUpload } from '../../HubFooter/HubFooter.helper';
import { CATEGORY_CAMERA_PATH } from '../../PhotosHub.helpers';
import LocalTexts from '../../VehiclePhotos.json';
import { aiDamagedPhotoGaHandler, damageCategoryPhotoGaEventHandler } from '../CategoryDamage.helper';
import { aiPhotoInputGaHandler, categoryPagePhotoGaEventHandler } from '../CategoryPage.helpers';

import PhotoItem from './PhotoItem/PhotoItem';
import { deletePhoto, getImageAssessmentStatus } from './PhotoBlock.helpers';

import styles from './PhotoBlock.module.scss';

const LocalT = new MDText(LocalTexts);

type AllImageCategoryType = ImageCategoryType | DamageCategoryType;

type PhotoBlockProps = {
  category: AllImageCategoryType;
  categoryKinds: KindType[];
  imageAssistModalHandler?: (args: { imageAssessmentStatus: ImageAssessmentsStatuses; photo?: Photo }) => void;
  kind: KindType;
  uploadingQueue?: UploadingQueue;
};

const PhotoBlock = ({
  category, categoryKinds, imageAssistModalHandler, kind, uploadingQueue = {},
}: PhotoBlockProps) => {
  const { add } = useToast();
  const navigate = useNavigate();
  const [inProgress, setInProgress] = useState<number | null>(null);
  const { deletedPhotos, removePhoto, sortedPhotos, updatePhoto, updatePhotoDamageMeta }: any = usePhotosContext();

  const { showAiImageAssistDamage } = useFeatureFlags();
  const { seller, setParentState, videoInfo: { imageCategories } } = useContext(Context) as ContextValueTypes;
  const { imageAssessments } = useSocket();

  const imageAssessment: Assessment[] = imageAssessments[category as keyof typeof imageAssessments];

  const filteredImageAssessment = imageAssessment.filter((assessment) => assessment.kind === kind) || [];
  const photos = sortedPhotos[category]?.filter((photo: Photo) => photo.kind === kind) || [];
  const isDamage = category === VEHICLE_PHOTOS_CATEGORY_KEY.DAMAGE;
  const deleted = isDamage ? [] : deletedPhotos.filter((photo: Photo) => photo.kind === kind);
  const title = imageCategories[category]
    ?.find((x) => x.kind === kind)?.description ?? kind;
  const currentPhotos = photos.length > 0 ? photos : deleted;

  const kindsWithPhotos = sortedPhotos[category]?.map((photo: Photo) => photo.kind) || [];

  const isCurrent = categoryKinds.filter((item) => !kindsWithPhotos.includes(item))?.[0] === kind;
  const isRetake = (photo?: Photo) => retakeStatuses().includes(photo?.status ?? '');
  const isApproved = useIsStatusRestricted();
  const deletePhotoHandler = (
    photo: Photo,
    imageAssessmentStatus?: ImageAssessmentsStatuses,
    AiImageAssistActive?: boolean,
  ) => {
    const deletePhotoAlert = LocalT.translate('vehiclePhotos.alerts.delete') as string;
    if (window.confirm(deletePhotoAlert)) { // eslint-disable-line no-alert
      deletePhoto({
        img: photo,
        removePhoto,
        seller,
        setInProgress,
        toastAdd: add,
      });
    }
    damageCategoryPhotoGaEventHandler({ kind });
    aiDamagedPhotoGaHandler({
      autoAssessStatus: imageAssessmentStatus,
      fireAiImageAssistGaEvent: AiImageAssistActive,
      kind,
    });
  };

  const takePhotoHandler = (
    photo?: Photo,
    imageAssessmentStatus?: ImageAssessmentsStatuses,
    AiImageAssistActive?: boolean,
  ) => {
    navigate(`${CATEGORY_CAMERA_PATH}`);

    // GA Events
    categoryPagePhotoGaEventHandler({
      category: category as DamageCategoryType,
      isRetake: isRetake(photo),
      kindDescription: title,
    });
    aiPhotoInputGaHandler({
      autoAssessStatus: imageAssessmentStatus,
      category: category as DamageCategoryType,
      fireAiImageAssistGaEvent: AiImageAssistActive,
      kindDescription: title,
    });
  };

  const photoActionHandler = (photo?: Photo, imageAssessmentStatus?: ImageAssessmentsStatuses) => {
    CATEGORY_PAGE_EVENTS.CATEGORY_PAGE_PHOTOS_BLOCK_CLICK(kind);

    const hasAutoAssessStatus = !!(photo?.autoAssessStatus ?? filteredImageAssessment?.[0]?.autoAssessStatus);
    const AiImageAssistActive = photo?.category === IMAGE_CATEGORIES.DAMAGE
      ? showAiImageAssistDamage && hasAutoAssessStatus
      : hasAutoAssessStatus;

    const isStatusSuccess = imageAssessmentStatus === IMAGE_ASSESSMENTS_STATUSES.SUCCESS;
    const isStatusPending = imageAssessmentStatus === IMAGE_ASSESSMENTS_STATUSES.PENDING;
    const isStatusFalsePositive = photo?.isAutoAssessStatusFalsePositive;

    if (AiImageAssistActive && !isDamage && !isStatusSuccess && !isStatusPending && !isStatusFalsePositive) {
      return imageAssistModalHandler?.({ imageAssessmentStatus: imageAssessmentStatus ?? null, photo });
    }
    if (isDamage && photo) {
      return deletePhotoHandler(photo, imageAssessmentStatus, AiImageAssistActive);
    }
    if (photo) {
      setParentState({ imageToReplace: photo });
    }

    return takePhotoHandler(photo, imageAssessmentStatus, AiImageAssistActive);
  };

  const submitPhoto = (img: Photo) => {
    triggerUpload(img, seller.auth_token, updatePhoto, updatePhotoDamageMeta).catch(() => {
      add(
        (
          <div>
            <h6>{LocalT.translate('vehiclePhotos.errors.upload')}</h6>
            <p>{LocalT.translate('vehiclePhotos.errors.noInternet')}</p>
          </div>
        ), ToastStatuses.ERROR,
        {
          longMessage: true,
        },
      );
    });
  };

  return (
    <div className={styles.photo}>
      {currentPhotos.map((photo: Photo) => {
        const uploadingItem: Stats = uploadingQueue[photo.id as keyof typeof uploadingQueue];
        const imageAssessmentStatus = getImageAssessmentStatus({
          filteredImageAssessment,
          photo,
          showAiImageAssistDamage,
        });
        return (
          <PhotoItem
            {...imageAssessmentStatus}
            key={photo.id}
            handleChange={() => photoActionHandler(photo, imageAssessmentStatus?.imageAssessmentStatus)}
            id={photo.id}
            img={photo}
            inProgress={inProgress === photo.id}
            isApproved={isApproved}
            isDamage={isDamage}
            isRetake={isRetake(photo)}
            kind={kind}
            submitPhoto={() => submitPhoto(photo)}
            title={title}
            uploadingProgress={uploadingItem?.percentage}
          />
        );
      })}

      {!photos.concat(deleted).length && (
        <PhotoItem
          handleChange={() => photoActionHandler()}
          isCurrent={isCurrent}
          kind={kind}
          submitPhoto={() => undefined}
          title={title}
        />
      )}
    </div>
  );
};

export default PhotoBlock;
