import { datadogRum } from '@datadog/browser-rum';

import { captureDatadogException } from '../../../utilities/logger';
import type { ImageCategoryType } from '../../../utilities/Types/vehiclePhotosCategories.types';
import { IMAGE_CATEGORIES } from '../../../utilities/vehiclePhotosCategories';

import type { AddError, ImageAssessmentsStatuses, UpdateImageAssessmentFunctionTypes } from './Socket.types';

// eslint-disable-next-line no-underscore-dangle
export const SOCKET_SERVER_URL = window.__ENV__.sellerEventsUrl;

export const IMAGE_ASSESSMENTS_STATUSES = {
  BLURRED_IMAGE: 'blurred_image',
  BOOT_CLOSED: 'boot_closed',
  EXTRACT_AREA_TOO_SMALL: 'extract_area_too_small',
  FAILED_ATTEMPT_RETRYING: 'failed_attempt_retrying',
  HAS_FINGER: 'has_finger',
  INCORRECT_ORIENTATION: 'incorrect_orientation',
  INCORRECT_POSITION: 'incorrect_position',
  NO_TYRE_DETECTED: 'no_tyre_detected',
  NO_VEHICLE_DETECTED: 'no_vehicle_detected',
  NO_WHEEL_DETECTED: 'no_wheel_detected',
  PENDING: 'pending',
  SUCCESS: 'success',
  VEHICLE_CUT_OFF: 'vehicle_cut_off',
  WHEEL_CUT_OFF: 'wheel_cut_off',
} as const;

export const imageAssessmentFaultStatuses = [
  IMAGE_ASSESSMENTS_STATUSES.BLURRED_IMAGE,
  IMAGE_ASSESSMENTS_STATUSES.BOOT_CLOSED,
  IMAGE_ASSESSMENTS_STATUSES.EXTRACT_AREA_TOO_SMALL,
  IMAGE_ASSESSMENTS_STATUSES.FAILED_ATTEMPT_RETRYING,
  IMAGE_ASSESSMENTS_STATUSES.HAS_FINGER,
  IMAGE_ASSESSMENTS_STATUSES.INCORRECT_ORIENTATION,
  IMAGE_ASSESSMENTS_STATUSES.INCORRECT_POSITION,
  IMAGE_ASSESSMENTS_STATUSES.NO_VEHICLE_DETECTED,
  IMAGE_ASSESSMENTS_STATUSES.VEHICLE_CUT_OFF,
  IMAGE_ASSESSMENTS_STATUSES.WHEEL_CUT_OFF,
  IMAGE_ASSESSMENTS_STATUSES.NO_WHEEL_DETECTED,
  IMAGE_ASSESSMENTS_STATUSES.NO_TYRE_DETECTED,
] as ReadonlyArray<ImageAssessmentsStatuses>;

export const SOCKET_ACTION_TYPES = {
  CONNECT: 'CONNECT',
  ERROR: 'SET_ERROR',
  RESET_REFETCH: 'RESET_REFETCH',
  UPDATE_IMAGE_ASSESSMENTS: 'UPDATE_IMAGE_ASSESSMENTS',
} as const;

export const SOCKET_MW_SELLER_EVENTS = {
  VEHICLE_IMAGE_AUTO_ASSESS: 'VehicleImage:AutoAssess',
  VEHICLE_IMAGE_SUBSCRIBE: 'Subscribe',
} as const;

export const SOCKET_IO_EVENTS = {
  CONNECT: 'connect',
  CONNECT_ERROR: 'connect_error',
  DISCONNECT: 'disconnect',
  ERROR: 'error',
  RECONNECT: 'reconnect',
  RECONNECT_ATTEMPT: 'reconnect_attempt',
  RECONNECT_ERROR: 'reconnect_error',
} as const;

export const updateImageAssessment: UpdateImageAssessmentFunctionTypes = (existingAssessments, newAssessment) => {
  const splitKind = newAssessment.kind.split('_')[0];

  const defineCategory = () => {
    if (splitKind === 'tyre') {
      return IMAGE_CATEGORIES.TYRES;
    }

    if (splitKind === 'instrument') {
      return IMAGE_CATEGORIES.INTERIOR;
    }

    return splitKind;
  };

  const category = defineCategory() as ImageCategoryType;

  newAssessment.category = category;

  // "tyres" is part of the "wheels" category, used for sorting purposes.
  const stateCategory = category === IMAGE_CATEGORIES.TYRES ? IMAGE_CATEGORIES.WHEELS : category;

  const existingCategoryAssessments = existingAssessments[stateCategory] || [];

  // return a new array where the existing assessment is replaced by the new one if the kinds match
  // and the category is not damage.
  const updatedCategoryAssessments = existingCategoryAssessments.map((assessment) => {
    const shouldUpdateAssessment = assessment.kind === newAssessment.kind && category !== IMAGE_CATEGORIES.DAMAGE;
    const updateAssessment = shouldUpdateAssessment ? newAssessment : assessment;

    return updateAssessment;
  });

  // If the new assessment was not found and replaced in the existing assessments,
  // new assessment should be added to the array
  if (!updatedCategoryAssessments.find((assessment) => assessment.vehicleImageId === newAssessment.vehicleImageId)) {
    updatedCategoryAssessments.push(newAssessment);
  }

  return {
    ...existingAssessments,
    [stateCategory]: updatedCategoryAssessments,
  };
};

const WEB_SOCKET = 'WebSocketAI';

export const addAction = (description: string, context?: Record<string, unknown>) => datadogRum.addAction(`${WEB_SOCKET} ${description}`, {
  tag: WEB_SOCKET,
  type: description,
  ...context,
});

export const addTiming = (description: string, start: number, end: number, context?: Record<string, unknown>) => {
  const duration = end - start;

  return datadogRum.addAction(`${WEB_SOCKET} ${description}`, { duration, tag: WEB_SOCKET, type: description, ...context });
};

export const addError = ({ context, description, errorMessage, isWarning }: AddError) =>
  captureDatadogException({
    context,
    error: errorMessage,
    fingerprint: `websocket-${description}`,
    isWarning,
    tags: {
      request: WEB_SOCKET,
    },
  });
