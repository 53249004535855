import React, { useEffect } from 'react';

import { MDText } from 'i18n-react';

import { LoadingSpinner } from '@motorway/motorway-storybook-cra';

import { GENERAL_EVENTS } from '../../../utilities/analytics/events/general/generalEvents';
import { setCssVar } from '../../../utilities/helpers';

import LocalTexts from './ScrollDownHint.json';

import styles from './ScrollDownHint.module.scss';

const LocalT = new MDText(LocalTexts);

type ScrollDownHintProps = {
  close: (status: boolean) => void;
};

export const ScrollDownHint = ({ close }: ScrollDownHintProps) => {
  useEffect(() => {
    const height = window.outerHeight || window.innerHeight;
    const gap = document.body.clientHeight - height;

    const scrollPosition = () => {
      const perc = 100 - (((gap - window.scrollY) / gap) * 100);

      setCssVar({ '--scroll-down-hint-transform': `${perc * -1}%` });

      if ((window.scrollY + height) >= (document.body.clientHeight - 10)) {
        setTimeout(() => close(true), 200);
      }
    };

    window.addEventListener('scroll', scrollPosition, { passive: true });

    window.dataLayer.push({
      event: 'Pageview',
      pageTitle: 'Scroll down screen view',
    });

    return () => {
      window.removeEventListener('scroll', scrollPosition);

      window.dataLayer.push({
        event: 'Pageview',
        pageTitle: 'Scroll down screen view removed',
      });

      setCssVar({ '--scroll-down-hint-transform': '0' });
    };
  }, []);

  useEffect(() => GENERAL_EVENTS.SCROLL_DOWN_SHOWN(), []);

  return (
    <div className={styles.component}>
      <div>
        <p>{LocalT.translate('scrollDownHint.message')}</p>
        <LoadingSpinner
          className={styles.loader}
          size="large"
        />
      </div>
    </div>
  );
};
