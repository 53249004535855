import React from 'react';
import cx from 'classnames';

import {
  CrossCircleIcon, DamageAlloyIcon,
  DamageDentIcon,
  DamageMissingTrimIcon,
  DamagePaintworkIcon,
  DamageScratchIcon,
  DamageTyreProblemIcon,
  DamageWarningLightIcon,
  DamageWindscreenIcon,
  SpannerIcon,
  TickCircleIcon,
} from '@motorway/mw-highway-code';
import { LoadingIndicator } from '@motorway/mw-highway-code/alpha';

import AdditionalInfoIcon from '../../../../assets/images/additional-info';
import { IMAGE_STATUSES, isSellerSupplied, isTouched } from '../../../../utilities/helpers';
import type { Photo } from '../../../../utilities/Types/photo.types';
import type { KindType } from '../../../../utilities/Types/vehiclePhotosCategories.types';
import { IMAGE_CATEGORIES, VEHICLE_DAMAGE_KIND_KEY } from '../../../../utilities/vehiclePhotosCategories';
import { imageAssessmentFaultStatuses } from '../../../context/Socket/Socket.helpers';
import { Assessment } from '../../../context/Socket/Socket.types';
import { getOutStandingUploads } from '../PhotosHub.helpers';

import type { FilterImageAssessmentFunction, RenderDamagePhotosProps, SectionICon } from './Section.types';

import styles from './Section.module.scss';

const { DAMAGE_TYRES, DAMAGE_WHEELS } = VEHICLE_DAMAGE_KIND_KEY;

export const DAMAGE_ICONS = {
  damage_dents: <DamageDentIcon />,
  damage_mechanical_or_electrical: <SpannerIcon />,
  damage_missing_trims: <DamageMissingTrimIcon />,
  damage_other: <AdditionalInfoIcon />,
  damage_paintwork: <DamagePaintworkIcon />,
  damage_scratches: <DamageScratchIcon />,
  damage_tyres: <DamageTyreProblemIcon />,
  damage_warning_lights: <DamageWarningLightIcon />,
  damage_wheels: <DamageAlloyIcon />,
  damage_windscreen: <DamageWindscreenIcon />,
} as Readonly<Record<KindType, React.ReactElement>>;

export const renderDamagePhotos = ({
  conditionKind,
  isApproved = false,
  kind,
  photos = [],
  tyrePhotos = [],
  uploadingQueue = {},
  vehicleDetails,
  wheelPhotos = [],
}: RenderDamagePhotosProps) => {
  let damagePhotos;
  let outStandingDamageUploads;

  const photosList = photos.filter((photo) => photo.kind === kind && !photo.isBrokenImage);
  const wheelPhotosList = wheelPhotos.filter(({ isDamaged }) => isDamaged);
  const tyrePhotosList = tyrePhotos.filter(({ isDamaged }) => isDamaged);

  const hasDamage = !!photosList.length || isTouched(vehicleDetails[conditionKind]);
  const isDamageTouched = isTouched(vehicleDetails[conditionKind]);

  switch (kind) {
    case DAMAGE_WHEELS:
      damagePhotos = wheelPhotosList.length;
      break;
    case DAMAGE_TYRES:
      damagePhotos = tyrePhotosList.length;
      break;
    default:
      damagePhotos = photosList.length;
      outStandingDamageUploads = isApproved ? 0 : getOutStandingUploads(photosList, uploadingQueue);
      break;
  }

  return { damagePhotos, hasDamage, isDamageTouched, outStandingDamageUploads };
};

export const filterImageAssessment: FilterImageAssessmentFunction = (
  imageAssessment,
  photos,
  showAiImageAssistDamage,
) => {
  const photoSet = photos.reduce<Record<string, Photo>>((acc, photo) => {
    acc[photo.platformId] = photo;
    return acc;
  }, {});
  const assessmentSet = new Set(imageAssessment.map((assessment) => assessment.vehicleImageId));

  const removeDeduplicateAssessments = photos.filter((photo) => !assessmentSet.has(photo.platformId));
  const removeOutDatedImageAssessment = imageAssessment.filter((assessment) => !!photoSet[assessment.vehicleImageId])
    .map((assessment) => {
      const photo = photoSet[assessment.vehicleImageId];
      return {
        ...assessment,
        isAutoAssessStatusFalsePositive: photo?.isAutoAssessStatusFalsePositive,
      };
    });

  const combinedAssessments = [
    ...removeOutDatedImageAssessment,
    ...removeDeduplicateAssessments] as Assessment[];

  const assessmentsNeedingRetake = combinedAssessments.filter((assessment) => {
    const isAssessmentRetake = imageAssessmentFaultStatuses.includes(assessment?.autoAssessStatus)
      && isSellerSupplied(assessment?.status ?? IMAGE_STATUSES.SELLER_SUPPLIED)
      && !assessment.isAutoAssessStatusFalsePositive;

    return assessment.category === IMAGE_CATEGORIES.DAMAGE
      ? isAssessmentRetake && showAiImageAssistDamage
      : isAssessmentRetake;
  });

  return assessmentsNeedingRetake.length;
};

export const sectionIcon: SectionICon = (isDone, hasError, isPhotosUploading) => {
  let icon = null;
  switch (true) {
    case (isPhotosUploading):
      icon = (
        <div className={cx(styles.loading, styles.icon)} data-testid="tick-loading-icon">
          <LoadingIndicator showText={false} size="xsmall" />
        </div>
      );
      break;
    case (hasError):
      icon = (
        <div className={cx(styles.crossCircleIcon, styles.icon)} data-testid="tick-cross-circle-icon">
          <CrossCircleIcon filled size="xsmall" />
        </div>
      );
      break;
    case isDone:
      icon = (
        <div className={cx(styles.tickIcon, styles.icon)} data-testid="tick-circle-icon">
          <TickCircleIcon size="xsmall" />
        </div>
      );
      break;
    default:
      break;
  }

  return icon;
};
