/* eslint-disable react-hooks/exhaustive-deps */
import { useContext, useEffect, useState } from 'react';

import { ONBOARDING_EVENTS } from '../../../utilities/analytics/events/onboarding/onboardingEvents';
import {
  grabFrameAndroidDevices,
  setDeviceAppliedConstraints,
  setDeviceEnumerateDevices,
  setDeviceSelectedCamera,
  setDeviceSupportedConstraints,
} from '../../../utilities/deviceHelpers';
import { captureDatadogException } from '../../../utilities/logger';
import { Context } from '../../context/context';

export type ResolutionSize = {
  aspectRatio?: ConstrainDoubleRange;
  height: { ideal?: number, min?: number },
  width: { ideal?: number, min?: number }
};

type ApplyConstraints = {
  aspectRatio?: ConstrainDoubleRange;
  deviceId?: string;
  facingMode?: ConstrainDOMString;
  torch: boolean;
  zoom: boolean;
};

export const useGetDeviceConstraints = ({ deviceId }: { deviceId?: string }) => {
  const [deviceConstraints, setDeviceConstraints] = useState<MediaTrackConstraints & ResolutionSize | null>(null);
  const [resolutionSizes, setResolutionSizes] = useState<ResolutionSize[]>();
  const [enable1080, setEnable1080] = useState<boolean | null>(null);

  useEffect(() => {
    const { mediaDevices } = navigator;
    const constraints = mediaDevices.getSupportedConstraints() || {};

    const applyConstraints: ApplyConstraints = {
      facingMode: 'environment',
      torch: false,
      zoom: false,
      // frameRate: 60
    };

    if (deviceId) {
      applyConstraints.deviceId = deviceId;
    }

    if ('aspectRatio' in constraints) {
      applyConstraints.aspectRatio = { exact: 1.3333333333 };
    }

    if ('height' in constraints && 'width' in constraints) {
      const deviceResolutionSizes = [
        {
          height: { ideal: 1080, min: 480 },
          width: { ideal: 1440, min: 640 },
        },
        {
          height: { min: 480 },
          width: { min: 640 },
        },
      ];
      setResolutionSizes(deviceResolutionSizes);

      // Chrome on Android will fail to display anything without erroring if above 1080p
      const shouldEnable1080 = (enable1080 || /Android/.test(window.navigator.userAgent));
      setEnable1080(shouldEnable1080);
      const dimensions = (shouldEnable1080) ? deviceResolutionSizes[0] : {
        height: { ideal: 2160, min: 480 },
        width: { ideal: 2880, min: 640 },
      };

      setDeviceConstraints(Object.assign(applyConstraints, dimensions));
    } else {
      setDeviceConstraints(null);
    }
  }, [deviceId]);

  return { deviceConstraints, enable1080, resolutionSizes, setDeviceConstraints, setEnable1080, setResolutionSizes };
};

const useGetMediaDevice = () => {
  const [deviceId, setDeviceId] = useState<string>();
  const [permissionsGranted, setPermissionsGranted] = useState<boolean | null>(null);
  const [permissionsDenied, setPermissionsDenied] = useState<boolean | null>(null);

  useEffect(() => {
    const { mediaDevices, userAgent } = navigator;

    mediaDevices.enumerateDevices()
      .then((devices) => {
        // https://stackoverflow.com/a/51516788
        const getPermissionsGranted = devices.filter((v) => v.label).length > 0;

        // Huawei phones for some reason, default to their most zoomed in camera
        const { deviceId: newDeviceId } = (
          grabFrameAndroidDevices
          || /Huawei/i.test(userAgent) // Default Huawei browser `HuaweiBrowser`
          || /(CLT|VOG|EML)-L[0-9]/.test(userAgent) // Model names P20|P30
        )
          ? (devices)
            .filter(({ kind }) => (!/^audio/.test(kind))) // Remove microphones
            // @ts-ignore name is in list
            .filter(({ name }) => (!/front/i.test(name))) // Try to avoid front facing camera
            // "The ONLY consistent way I've found to choose an environment-facing "normal" camera...
            // ...in 100% of cases is to call enumerateDevices and choose the LAST item."
            // https://www.reddit.com/r/javascript/comments/8eg8w5/choosing_cameras_in_javascript_with_the/dxvqycs
            .reverse()[0] || {} as MediaDeviceInfo
          : {} as MediaDeviceInfo;

        setDeviceId(newDeviceId);
        setPermissionsGranted(getPermissionsGranted);
      });
  }, []);

  return { deviceId, permissionsDenied, permissionsGranted, setPermissionsDenied, setPermissionsGranted };
};

export const useCreateVideo = ({
  $video,
}: { $video: React.MutableRefObject<HTMLVideoElement | null> }) => {
  const { setParentState }: any = useContext(Context);
  const [dataAvailable, setDataAvailable] = useState<boolean | null>(null);
  const [visibilityHideTiming, setVisibilityHideTiming] = useState<number>();
  const [error, setError] = useState<{ message: string, name: string }>();

  const {
    deviceId,
    permissionsDenied,
    permissionsGranted,
    setPermissionsDenied,
    setPermissionsGranted,
  } = useGetMediaDevice();

  const {
    deviceConstraints,
    resolutionSizes,
    setDeviceConstraints,
    setEnable1080,
    setResolutionSizes,
  } = useGetDeviceConstraints({ deviceId });

  const enableCamera = (is1080?: boolean) => {
    setEnable1080(is1080 === true);
    setPermissionsGranted(true);
  };

  const visibilityChange = () => {
    const video = $video.current;
    const track = window.cameraStream?.getTracks?.()[0]; // https://stackoverflow.com/a/35872215
    const { hidden } = document;

    if (!hidden) {
      requestAnimationFrame(() => {
        window.dataLayer.push({
          event: 'UATiming',
          timingCategory: 'General',
          timingLabel: 'visibilityChange',
          timingValue: Math.round(performance.now() - (visibilityHideTiming || 0)),
          timingVar: `getTracks - ${!!track}`,
        });
      });
    }

    if (!track) {
      return;
    }

    if (hidden === false && video?.paused === true) {
      track.enabled = true;
      video?.play()
        .catch((err) => {
          captureDatadogException({
            context: { visibilityChange: hidden },
            error: err,
            errorTitle: 'Error during play of camera video',
            fingerprint: 'play-camera-video',
          });
        });
    } else if (hidden) {
      // I'm just being nice here and saving people's battery
      setVisibilityHideTiming(performance.now());
      track.enabled = false;
      video?.pause();
    }
  };

  useEffect(() => {
    // iOS will pause the video when switching tabs but won't resume it when switching back
    document.addEventListener('visibilitychange', visibilityChange, false);
    return () => {
      document.removeEventListener('visibilitychange', visibilityChange, false);
      window.cameraStream?.getTracks?.()[0].stop();
    };
  }, [deviceId]);

  useEffect(() => {
    const videoConstraints = deviceConstraints;
    const video = $video.current;

    const loadedmetaHandler = () => {
      video?.play()
        .catch((err) => {
          captureDatadogException({
            error: err,
            errorTitle: 'Error during play of camera video on loadedmetaHandler',
            fingerprint: 'play-camera-video',
          });
        });
      setDeviceConstraints(videoConstraints);
      setDataAvailable(true);
      setError(undefined);
    };

    const createVideo = (retry = false) => {
      ['autoplay', 'muted', 'playsinline'].forEach((v) => {
        video?.setAttribute(v, '');
      });

      if (video) {
        video.srcObject = null;
        video.src = '';
      }

      if (window.cameraStream) {
        window.cameraStream.getTracks().forEach((track) => track.stop());
        window.cameraStream.getVideoTracks().forEach((track) => track.stop());
        window.cameraStream.getAudioTracks().forEach((track) => track.stop());
        window.cameraStream = null;
      }

      navigator.mediaDevices.getUserMedia({
        audio: false,
        video: videoConstraints as MediaTrackConstraints,
      })?.then((videoStream) => {
        if (!retry) {
          try {
            setDeviceEnumerateDevices();
            setDeviceAppliedConstraints(videoConstraints);
            setDeviceSelectedCamera(videoStream.getVideoTracks()[0]?.getSettings?.());
            setDeviceSupportedConstraints();
          } catch (err) {
            console.error(err);
            captureDatadogException({
              error: err,
              errorTitle: 'Error during setting device info',
              fingerprint: 'device-info',
            });
          }

          setParentState({
            createVideo,
            video,
          });
        }
        window.cameraStream = videoStream;
        if (video) {
          if (!retry) {
            video.addEventListener('loadedmetadata', loadedmetaHandler);
          }
          if ('srcObject' in video) {
            video.srcObject = videoStream;
          } else {
            // @ts-ignore you can use MediaStream
            video.src = window.URL.createObjectURL(videoStream);
          }
        }
      }).catch((err) => {
        if (err.name === 'NotAllowedError' || err.name === 'PermissionDismissedError') {
          setPermissionsDenied(true);

          window.dataLayer.push({
            event: 'UAEvent',
            eventAction: 'Camera - Permission',
            eventCategory: 'Image capture',
            eventLabel: 'Denied',
          });

          ONBOARDING_EVENTS.DENY_CAMERA_PERMISSION_CLICK();

          return;
        }

        if (err && err?.constraint) {
          if (err.constraint === 'aspectRatio' && videoConstraints?.aspectRatio?.exact) {
            videoConstraints.aspectRatio = {
              ideal: videoConstraints.aspectRatio.exact,
            };
          } else if (resolutionSizes?.[0] && (err.constraint === 'height' || err.constraint === 'width')) {
            const oldResolutionSizes = resolutionSizes;
            const newSize = oldResolutionSizes[0];

            if (videoConstraints) {
              videoConstraints.height = newSize.height;
              videoConstraints.width = newSize.width;
            }
            oldResolutionSizes.shift();
            setResolutionSizes(oldResolutionSizes);
          } else if (videoConstraints) {
            delete videoConstraints[err.constraint as keyof typeof videoConstraints];
          }

          createVideo(retry);
        } else {
          setError(err);
          throw new Error(err);
        }
      });
    };

    if (videoConstraints) {
      if (permissionsGranted) {
        createVideo();
      } else {
        enableCamera();
      }
    }

    return () => {
      $video?.current?.removeEventListener('loadedmetadata', loadedmetaHandler);
    };
  }, [permissionsGranted, setParentState]);

  return {
    dataAvailable,
    deviceConstraints,
    enableCamera,
    error,
    permissionsDenied,
    permissionsGranted,
    visibilityChange,
  };
};
