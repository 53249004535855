/* eslint-disable import/prefer-default-export */

import React from 'react';

import { MDText } from 'i18n-react';

import { ToastStatuses } from '@motorway/motorway-storybook-cra';
import {
  BinIcon, CameraIcon, CameraRetakeIcon, CrossCircleIcon,
  DashCircleIcon, ErrorIcon,
  IconComponentProps,
  LockClosedIcon, MagnifyingGlassIcon, PencilIcon, RotateIcon, TickCircleIcon, UploadIcon,
} from '@motorway/mw-highway-code';
import { INFOBOX_VARIANT, SIZE, VARIANT } from '@motorway/mw-highway-code/enums';

import { isOnLine } from '../../../../../utilities/hooks';
import { archiveImage, deletedImageUpdateDB, deleteImageFromLocalStorage } from '../../../../../utilities/imageActions';
import { captureDatadogException } from '../../../../../utilities/logger';
import { IMAGE_CATEGORIES } from '../../../../../utilities/vehiclePhotosCategories';
import { IMAGE_ASSESSMENTS_STATUSES, imageAssessmentFaultStatuses } from '../../../../context/Socket/Socket.helpers';

import LocalTexts from './PhotoItem/PhotoItem.json';
import type { DeletePhotoType, GetImageAssessmentStatusProps, PhotoItemContentProps, SubtitleProps } from './PhotoItem/PhotoItem.types';

import styles from './PhotoBlock.module.scss';

const LocalT = new MDText(LocalTexts);

export const getImageAssessmentStatus = ({
  filteredImageAssessment,
  photo,
  showAiImageAssistDamage,
}: GetImageAssessmentStatusProps) => {
  if (photo.category === IMAGE_CATEGORIES.DAMAGE && !showAiImageAssistDamage) {
    return {
      faultDetected: false,
      imageAssessmentPending: false,
      imageAssessmentStatus: null,
      imageAssessmentSuccessful: false,
    };
  }

  const findImageAssessment = filteredImageAssessment.find(
    (assessment) => assessment.vehicleImageId === photo.platformId,
  );

  const photoAutoAssessStatus = photo?.autoAssessStatus ?? null;
  const socketAutoAssessStatus = findImageAssessment?.autoAssessStatus ?? null;

  if (!socketAutoAssessStatus && !photoAutoAssessStatus) {
    return {
      faultDetected: false,
      imageAssessmentPending: true,
      imageAssessmentStatus: null,
      imageAssessmentSuccessful: false,
    };
  }

  const isFalsePositive = photo.isAutoAssessStatusFalsePositive;

  const imageAssessmentStatus = socketAutoAssessStatus ?? photoAutoAssessStatus;
  const faultDetected = imageAssessmentFaultStatuses.includes(imageAssessmentStatus) && !isFalsePositive;
  const imageAssessmentSuccessful = imageAssessmentStatus === IMAGE_ASSESSMENTS_STATUSES.SUCCESS || isFalsePositive;
  return { faultDetected, imageAssessmentPending: false, imageAssessmentStatus, imageAssessmentSuccessful };
};

const createSubtitle = (
  className: string | null,
  Icon: React.FC<IconComponentProps> | null,
  translationKey: string,
): SubtitleProps => ({
  className,
  icon: Icon ? <Icon size={SIZE.XSMALL} /> : null,
  subtitleText: LocalT.translate(translationKey) as string,
});

const createFormProps = (onClickHandler: () => void) => ({
  input: {
    onClick: (ev: React.MouseEvent<HTMLInputElement>) => {
      ev.preventDefault();
      onClickHandler();
    },
  },
});

export const renderContent = ({
  faultDetected,
  handleChange,
  handleShowLockedMessage,
  imageAssessmentPending,
  imageAssessmentStatus,
  imageAssessmentSuccessful,
  inProgress,
  isApproved = false,
  isBrokenImage = false,
  isCurrent = false,
  isDamage = false,
  isRetake = false,
  isSellerSupplied = true,
  isUploaded = false,
  isUploading = false,
  photo,
  submitPhoto,
}: PhotoItemContentProps) => {
  if (isBrokenImage) {
    const subtitle = createSubtitle(styles.isBrokenImage, CrossCircleIcon, 'blocked');
    return {
      buttonProps: {
        icon: isDamage ? BinIcon : CameraRetakeIcon,
        onClick: handleChange,
        variant: isDamage ? VARIANT.SECONDARY : VARIANT.PRIMARY,
      },
      formProps: createFormProps(handleChange),
      subtitle,
    };
  }

  if (inProgress) {
    return {
      loading: true,
      removeInput: true,
      showBlackOverlay: true,
    };
  }

  if (isUploading) {
    return {
      loading: true,
      removeInput: true,
      showBlackOverlay: true,
      subtitle: createSubtitle(null, UploadIcon, 'uploading'),
    };
  }

  if (isCurrent) {
    return {
      buttonProps: { icon: CameraIcon, onClick: handleChange },
      formProps: createFormProps(handleChange),
      subtitle: null,
    };
  }

  if (!photo) {
    return {
      formProps: { disabled: true },
      rightIcon: null,
      subtitle: createSubtitle(null, LockClosedIcon, 'disabled'),
    };
  }

  if (photo.skipped) {
    const subtitle = createSubtitle(styles.isSkipped, DashCircleIcon, 'skipped');
    return {
      buttonProps: {
        icon: isApproved ? LockClosedIcon : PencilIcon,
        onClick: handleChange,
        variant: isApproved ? VARIANT.PRIMARY : VARIANT.SECONDARY,
      },
      formProps: createFormProps(handleChange),
      removeInput: isApproved,
      subtitle,
    };
  }

  if (isRetake) {
    return {
      buttonProps: {
        icon: CameraRetakeIcon,
        onClick: handleChange,
      },
      formProps: createFormProps(handleChange),
      subtitle: createSubtitle(styles.isRetake, CrossCircleIcon, 'retake'),
    };
  }

  if (!isUploaded) {
    return {
      buttonProps: {
        icon: RotateIcon,
        onClick: submitPhoto,
      },
      formProps: createFormProps(submitPhoto),
      showBlackOverlay: true,
      subtitle: createSubtitle(styles.isFailed, CrossCircleIcon, 'failed'),
    };
  }

  if (isApproved) {
    return {
      buttonProps: {
        icon: LockClosedIcon,
        variant: VARIANT.TERTIARY,
      },
      formProps: createFormProps(handleShowLockedMessage),
      subtitle: createSubtitle(styles.isApproved, TickCircleIcon, 'approved'),
    };
  }

  if (faultDetected && isSellerSupplied) {
    const subtitleText = `imageAssessment.statuses.${imageAssessmentStatus}`;

    return {
      buttonProps: {
        icon: isDamage ? BinIcon : () => <ErrorIcon filled={false} />,
        onClick: handleChange,
        variant: isDamage ? VARIANT.SECONDARY : VARIANT.DESTRUCTIVE,
      },
      feedbackBar: (
        <div className={styles.aiFeedbackBox} onClick={handleChange} role='button'>
          <div className={styles.aiFeedback}>
            <svg fill="none" height="24" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg">
              <rect fill="white" fillOpacity="0.25" height="20" rx="4" width="23" x="0.5" y="2" />
              <path d="M10.044 17L9.408 14.768H6.744L6.108 17H4.74L7.188 8.624H9.024L11.472 17H10.044ZM8.124 9.98H8.028L6.996 13.652H9.156L8.124 9.98ZM12.6553 17V15.98H14.6233V9.644H12.6553V8.624H17.9353V9.644H15.9673V15.98H17.9353V17H12.6553Z" fill="white" />
            </svg>
            {LocalT.translate(subtitleText)}
          </div>
          <span className={styles.aiLink}>
            {LocalT.translate('imageAssessment.more')}
          </span>
        </div>
      ),
      feedbackBarVariant: INFOBOX_VARIANT.ERROR,
      formProps: createFormProps(handleChange),
    };
  }

  if (imageAssessmentPending) {
    const subtitleText = 'imageAssessment.pending';
    const subtitle = createSubtitle(styles.imageAssessmentPending, MagnifyingGlassIcon, subtitleText);
    return {
      loading: true,
      removeInput: true,
      showBlackOverlay: true,
      subtitle,
    };
  }

  if (imageAssessmentSuccessful) {
    const subtitleText = 'imageAssessment.statuses.success';
    const subtitle = createSubtitle(null, null, subtitleText);
    return {
      buttonProps: {
        icon: isDamage ? BinIcon : PencilIcon,
        onClick: handleChange,
        variant: VARIANT.SECONDARY,
      },
      formProps: createFormProps(handleChange),
      subtitle,

    };
  }

  return {
    buttonProps: {
      icon: isDamage ? BinIcon : PencilIcon,
      onClick: handleChange,
      variant: VARIANT.SECONDARY,
    },
    formProps: createFormProps(handleChange),
    subtitle: photo?.damageMeta?.size
      ? createSubtitle(null, null, `damageSize.${photo.damageMeta.size}`)
      : null,
  };
};

const reloadHandler = (e: React.MouseEvent<HTMLElement>) => {
  e.preventDefault();
  window.location.reload();
};

export const deletePhoto = async ({
  img,
  removePhoto,
  seller,
  setInProgress,
  toastAdd,
}: DeletePhotoType) => {
  if (img?.isBrokenImage) {
    removePhoto(img.id);
  } else {
    setInProgress(img.id);
    try {
      if (img?.platformId) {
        const res = await archiveImage(
          {
            authToken: seller.auth_token,
            platformId: img.platformId,
          },
        );

        if (res?.status === 'failed') {
          throw new Error(res?.statusMessage);
        }
      }
      deleteImageFromLocalStorage(img);
      deletedImageUpdateDB(img);
      removePhoto(img.id);
    } catch (error) {
      captureDatadogException({
        context: { isOnLine: isOnLine(), platformId: img?.platformId },
        error,
        fingerprint: 'fetch-archive-image',
      });
      toastAdd(
        (
          <div>
            <p className={styles.errorInfo}>{LocalT.translate('errors.difficulties')}</p>
            <h6>
              <a
                className={styles.link}
                href="#error"
                onClick={reloadHandler}
              >
                {LocalT.translate('errors.link')}
              </a>
              {LocalT.translate('errors.again')}
            </h6>
          </div>
        ), ToastStatuses.ERROR,
        {
          longMessage: true,
        },
      );
    }

    setInProgress(null);
  }
};
