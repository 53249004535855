import React, { useContext, useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import cx from 'classnames';

import { CAMERA_PAGE_EVENTS } from '../../../utilities/analytics/events/cameraPage/cameraPageEvents';
import { CATEGORY_PAGE_EVENTS } from '../../../utilities/analytics/events/categoryPage/categoryPageEvents';
import { getIndexFromPhotoInCategory, getIsCategoryDone, getNextPhotoIndex } from '../../../utilities/nextPhotoIndex';
import { Photo } from '../../../utilities/Types/photo.types';
import type { CategoryType } from '../../../utilities/Types/vehiclePhotosCategories.types';
import { HELP_PANEL_DAMAGE_KINDS } from '../../../utilities/vehiclePhotosCategories';
import { Context } from '../../context/context';
import { handleOpenHelp } from '../DamagePanel/DamagePanel.helpers';
import HelpButton from '../HelpButton/HelpButton';

import type { HelpButtonType } from './Components';
import { CaptureButton, CategoryLabel, CategoryOnboardingMessage, SkipOrHelpButton } from './Components';
import { useGetImage } from './ImageCapture.hook';
import {
  calculateVisibleVideoSize,
  DAMAGE_LOCATION_CATEGORIES,
  dataLayerPush,
  displaySkip,
  getCurrentImageItem,
  getHelpSearch,
  renderEventCategory,
  renderEventLabel,
  STREAM_LISTENERS,
  updateWorkerStreamState,
} from './Overlay.helpers';
import { useGoToNextTimings, useUpdateContextPhoto } from './Overlay.hooks';
import { SkipInfo } from './Overlay.types';
import { activatePlay, DimensionsType, displayFirstFrame, onVideoError, setUpVideo } from './Video.helper';

import styles from './Overlay.module.scss';

type OverlayProps = {
  categoryImages: Photo[],
  currentCategory: CategoryType[],
};

const Overlay: React.FC<OverlayProps> = (
  { categoryImages = [], currentCategory = [] },
) => {
  const navigate = useNavigate();

  const context: any = useContext(Context);

  const {
    imageToReplace, isLandscape, offer, setParentState,
    showDamageLocationOverlay, video, videoInfo,
  } = context;

  const videoInterval = useRef<NodeJS.Timeout>();
  const flashTimeout = useRef<NodeJS.Timeout>();
  const buttonTimeout = useRef<NodeJS.Timeout>();

  const $videoOutlines = useRef(null);

  const categoryDone = getIsCategoryDone(categoryImages, currentCategory);
  const replaceIndex = getIndexFromPhotoInCategory(currentCategory, imageToReplace);

  const stream = useRef<MediaStreamTrack | null>(null);
  const imageCapture = useRef<ImageCapture | null>(null);
  const imageCaptureCapabilities = useRef<PhotoSettings>(null);
  const [dimensions, setDimensions] = useState<DimensionsType | null>(null);
  const [imageCategoriesIndex, setImageCategoriesIndex] = useState(
    Number.isInteger(replaceIndex)
      ? replaceIndex : getNextPhotoIndex(categoryImages, currentCategory),
  );
  const [activatePlayOverlay, setActivatePlayOverlay] = useState(false);
  const [showHintTooltip, setShowHintTooltip] = useState(false);
  const [uploadingPhoto, setUploadingPhoto] = useState<Record<string, never> | null>(null);
  const [disabled, setDisabled] = useState(false);
  const [flash, setFlash] = useState(false);

  const [videoReady, setVideoReady] = useState(false);

  const initVideoSetUp = setUpVideo({
    $outline: $videoOutlines.current,
    $video: video,
    currentCategory,
    imageCapture,
    imageCaptureCapabilities,
    imageCategoriesIndex,
    setDimensions,
    stream,
    videoInterval,
  });

  const updateContextPhoto = useUpdateContextPhoto();

  const goToCategoryPage = (action?: string) => {
    setParentState({ imageToReplace: null });
    navigate(-1);
    const { kind } = getCurrentImageItem(currentCategory, imageCategoriesIndex);

    if (action?.length) {
      dataLayerPush(kind, action);
      CAMERA_PAGE_EVENTS.EXIT_BUTTON_CLICKED(kind);
    }
  };

  const getImageFromStream = useGetImage({
    currentCategory, goToCategoryPage, setDisabled, setUploadingPhoto,
  });

  useGoToNextTimings({
    $videoOutlines,
    currentCategory,
    imageCategoriesIndex,
    setActivatePlayOverlay,
    setDisabled,
    setShowHintTooltip,
    videoReady,
  });

  useEffect(() => {
    if ($videoOutlines.current) {
      initVideoSetUp();
    }

    return () => {
      imageCapture.current = null;
      clearInterval(videoInterval.current);
      clearTimeout(flashTimeout.current);
      clearTimeout(buttonTimeout.current);
      STREAM_LISTENERS.forEach((e) => stream.current?.removeEventListener?.(e, updateWorkerStreamState as never));
    };
  }, [$videoOutlines.current]);

  useEffect(() => {
    setDimensions(calculateVisibleVideoSize({ $video: video }));
  }, [isLandscape]);

  useEffect(() => {
    const { kind } = getCurrentImageItem(currentCategory, imageCategoriesIndex);
    CATEGORY_PAGE_EVENTS.CATEGORY_PAGE_CAMERA_VIEW_LOADED(kind);
  }, [currentCategory, imageCategoriesIndex]);

  useEffect(() => {
    if (uploadingPhoto) {
      const categoryItem = getCurrentImageItem(currentCategory, imageCategoriesIndex);
      const isRetakeCategory = !!imageToReplace;
      flashTimeout.current = setTimeout(() => setFlash(false), 250);
      buttonTimeout.current = setTimeout(() => {
        // Retaking photo, can mix the logic, but complicates it, so let's keep it separate
        if (isRetakeCategory) {
          setImageCategoriesIndex(getIndexFromPhotoInCategory(currentCategory, imageToReplace));
          return;
        }

        // If `category` is `damage` don't proceed to next category till all images are taken
        // Or if there's no category after this one, a.k.a the last one
        const step = currentCategory.length - 1 === imageCategoriesIndex ? 0 : 1;
        const nextIndex = categoryItem.multiple ? imageCategoriesIndex
          : (imageCategoriesIndex ?? -1) + step;

        setDisabled(false);
        setImageCategoriesIndex(nextIndex);
      }, 450);

      getImageFromStream({
        categoryItem, imageCapture, imageCaptureCapabilities, imageCategoriesIndex, stream,
      });
    }
  }, [uploadingPhoto]);

  if (!dimensions) {
    return (null);
  }

  const { timings } = currentCategory[imageCategoriesIndex ?? -1] || {};

  const categoryItem = getCurrentImageItem(currentCategory, imageCategoriesIndex);
  const disableUI = DAMAGE_LOCATION_CATEGORIES.includes(categoryItem.kind) && !!uploadingPhoto;
  const positioning = (dimensions) ? { height: dimensions.height, width: dimensions.width } : {};
  const skipInfo = displaySkip({
    categoryItem,
    currentCategory,
    imageCategoriesIndex,
    imageToReplace,
    offer,
    setImageCategoriesIndex,
    updateContextPhoto,
  });

  const captureImage = async () => {
    // To prevent race conditions, assign the category
    // It's not certain by the time the image is captured and processed
    // that React hasn't already updated the index for the next image
    const isRetakeCategory = !!imageToReplace;

    CATEGORY_PAGE_EVENTS.CATEGORY_PAGE_PHOTO_TAKEN_IN_CAMERA_VIEW(categoryItem.kind);

    if (isRetakeCategory) {
      updateContextPhoto(null, true);
    }

    setDisabled(true);
    setFlash(true);
    setUploadingPhoto({});
  };

  return (
    <>
      <div
        className={
          cx(
            styles.component,
            styles[categoryItem.kind],
            {
              [styles.disabled]: disabled || disableUI,
              [styles.hideOverlay]: !timings || !videoReady,
            },
          )
        }
        style={positioning}
      >
        <video
          ref={$videoOutlines}
          autoPlay
          muted
          playsInline
          className={styles.outlines}
          crossOrigin="anonymous"
          data-testid='video'
          onError={() => onVideoError($videoOutlines, setParentState)}
          onLoadedData={() => {
            if ($videoOutlines?.current) {
              displayFirstFrame({
                $outlines: $videoOutlines?.current,
                currentCategory,
                imageCategoriesIndex,
                setActivatePlayOverlay,
                setVideoReady,
              });
            }
          }}
          preload="auto"
        >
          {/* iOS 14 - https://stackoverflow.com/a/51812229 */}
          <source src={videoInfo.mov} type="video/quicktime; codecs=hvc1" />
          <source src={videoInfo.webm} type="video/webm;" />
          {/* Fallback */}
          <source src={videoInfo.mov} />
        </video>
        <div
          className={cx(styles.videoOverlay, { [styles.isActive]: activatePlayOverlay })}
          onClick={() => {
            if ($videoOutlines.current) {
              activatePlay({
                $outlines: $videoOutlines.current,
                setActivatePlayOverlay,
                setShowHintTooltip,
                setVideoReady,
              });
            }
          }}
          tabIndex={0}
        />
        <div className={cx(styles.sideBar, styles.sidebarLeft)}>
          <button
            className={styles.saveButton}
            onClick={goToCategoryPage.bind(this, imageToReplace ? 'Retake back clicked' : 'Exit icon clicked')}
            type="button"
          >
            {imageToReplace ? 'Back' : 'Exit'}
          </button>
          {!HELP_PANEL_DAMAGE_KINDS.includes(categoryItem.kind as HelpButtonType) && (
            <HelpButton
              className={styles.helpButton}
              onClick={() => handleOpenHelp({
                categoryItem,
                eventCategory: renderEventCategory(categoryItem),
                eventLabel: renderEventLabel(categoryItem),
                getHelpSearch,
                navigate,
              })}
            />
          )}
        </div>
        <div className={cx(styles.sideBar, styles.sidebarRight)}>
          <SkipOrHelpButton
            kind={categoryItem.kind as HelpButtonType}
            {...{ disableUI, skipInfo }}
          />
          <CaptureButton
            captureImage={captureImage}
            category={categoryItem.category}
            categoryDone={categoryDone && !imageToReplace}
            disabled={Boolean(timings && !videoReady)}
            {...{ flash, showHintTooltip }}
            skipInfo={skipInfo as SkipInfo}
          />
        </div>
        <CategoryLabel {...{ categoryItem, currentCategory }} />
      </div>
      <div className={cx(styles.flash, { [styles.active]: flash })} />

      <CategoryOnboardingMessage
        categoryImages={categoryImages}
        categoryItem={categoryItem}
        showDamageLocationOverlay={showDamageLocationOverlay}
        timings={timings as number[]}
        uploadingPhoto={uploadingPhoto}
      />
    </>
  );
};

export default Overlay;
