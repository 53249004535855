import { GENERIC_ANALYTICS_EVENTS } from '../../analyticsGenericEvents';
import { EVENT_CATEGORY } from '../events';

export const GENERAL_EVENTS = {
  ROTATE_DEVICE_SHOWN: () => GENERIC_ANALYTICS_EVENTS.SECTION_LOAD_EVENT({
    category: EVENT_CATEGORY,
    name: 'rotate_device_step',
  }),
  SCROLL_DOWN_SHOWN: () => GENERIC_ANALYTICS_EVENTS.SECTION_LOAD_EVENT({
    category: EVENT_CATEGORY,
    name: 'scroll_down_step',
  }),
} as const;
