import {
  Icon,
  IconComponentFunction,
  VehicleBackIcon,
  VehicleDriverSideIcon,
  VehicleFrontIcon,
  VehiclePassengerSideIcon,
  VehicleTopIcon,
} from '@motorway/mw-highway-code';

import { GA_EVENT_ACTIONS, GA_EVENTS } from '../../../utilities/analytics';
import { CAMERA_PAGE_EVENTS } from '../../../utilities/analytics/events/cameraPage/cameraPageEvents';
import { updatePhotoDamage } from '../../../utilities/api';
import { handleCatchIfOnline } from '../../../utilities/api/helpers';
import { updateDamageMetaDB } from '../../../utilities/imageActions';
import { DamageSide } from '../../../utilities/Types/panelDamage.types';
import { VEHICLE_PHOTOS_CATEGORY_KEY } from '../../../utilities/vehiclePhotosCategories';
import { PANEL_TYPES } from '../Help/HelpPanel.helpers';

import type { HandleOpenHelpProps, OnDamageMetaSubmitProps, UpdateDamageMetaProps } from './DamagePanel.types';

export const DATA_DOG_RUM_PANEL_DAMAGE = {
  DAMAGE_PANEL_PAGE: {
    ACTION_BACK: 'tap on panel-damage-back',
    ACTION_CANCEL: 'tap on panel-damage-cancel',
    ACTION_CONTINUE: 'tap on panel-damage-continue',
    ACTION_PANEL: 'tap on panel-damage-select-panel',
    ACTION_POINT: 'tap on panel-damage-select-point',
    ACTION_SIDE: 'tap on panel-damage-select-side',
    VIEW: 'vehicle-condition-panel-selection',
  },
  DAMAGE_SIZE_PAGE: {
    ACTION_BACK: 'tap on panel-damage-size-back',
    ACTION_SUBMIT: 'tap on panel-damage-size-submit',
    VIEW: 'vehicle-condition-damage-size',
  },
} as const;

export const PANEL_DAMAGE_GA_EVENTS = {
  CAMERA_PAGE: {
    PAGE_LOADED: 'Camera page - page loaded',
    PHOTO_TIPS_BUTTON: 'Photo tips - button clicked',
  },
  CATEGORY: 'Panel damage',
  PANEL_DAMAGE_PAGE: {
    HELP_BUTTON: 'Help modal - button clicked ',
    PAGE_LOADED: 'Damage panel - page loaded',
  },
  SIZE_DAMAGE_PAGE: {
    BACK_BUTTON: 'Size damage back button - button clicked',
    PAGE_LOADED: 'Size damage - page loaded',
  },
} as const;

export const updateDamageMeta = async ({ damageMeta, id, isReplace, updatePhotoDamageMeta }: UpdateDamageMetaProps) => {
  // indexedDB update
  await updateDamageMetaDB({ damageMeta, id });
  // context update
  await updatePhotoDamageMeta(id, damageMeta, isReplace);
};

export const onDamageMetaSubmit = async ({
  callback, damagePhotoDetails,
  goToCategoryPage, imageToReplace, meta, setParentState, updatePhotoDamageMeta,
}: OnDamageMetaSubmitProps) => {
  const { id, location } = damagePhotoDetails;
  const isReplace = !!imageToReplace;

  await updateDamageMeta({ damageMeta: { ...meta, uploaded: false }, id, isReplace, updatePhotoDamageMeta });

  if (location) {
    try {
      // server api call
      const res = await updatePhotoDamage({ damageMeta: meta, location });
      if (res?.ok) {
        const updatedMeta = { ...meta, uploaded: true };
        await updateDamageMeta({ damageMeta: updatedMeta, id, isReplace, updatePhotoDamageMeta });
      }
    } catch (error) {
      handleCatchIfOnline(error, 'fetch-update-photo-damage');
      console.error(error);
    }
  }

  if (callback) {
    callback?.();
  }

  setParentState({
    showDamageLocationOverlay: undefined,
  });

  if (isReplace) {
    goToCategoryPage();
  }
};

export const DAMAGE_GA_EVENT_LABEL = Object.freeze({
  CATEGORY: '{category} photo section',
  HELP_BUTTON_LABEL: '{kind} photo tips - button clicked',
});

export const handleOpenHelp = ({ categoryItem, eventCategory, eventLabel, getHelpSearch, navigate }:
HandleOpenHelpProps) => {
  const { category, kind } = categoryItem || {};
  const helpSearch = (category === VEHICLE_PHOTOS_CATEGORY_KEY.INTERIOR && kind) ? getHelpSearch(kind) : '';

  GA_EVENTS.DEFAULT(GA_EVENT_ACTIONS.BUTTON, eventCategory, eventLabel);
  CAMERA_PAGE_EVENTS.HELP_BUTTON_CLICKED(kind);

  navigate(`../${PANEL_TYPES.HELP}${helpSearch}`);
};

export const FALLBACK_SIDE_ICONS = {
  back: VehicleBackIcon,
  driver_side: VehicleDriverSideIcon,
  front: VehicleFrontIcon,
  passenger_side: VehiclePassengerSideIcon,
  top: VehicleTopIcon,
} as const as Record<string, IconComponentFunction>;

export const panelDamageSideIcon = (side: DamageSide) => {
  const { icon, id } = side;
  let sideIcon: IconComponentFunction = FALLBACK_SIDE_ICONS[id] || FALLBACK_SIDE_ICONS.front;
  if (icon?.svgChildren) {
    sideIcon = (props) => (
      <Icon {...props} >
        <svg height="48" viewBox="0 0 48 48" width="48" xmlns="http://www.w3.org/2000/svg">
          <g dangerouslySetInnerHTML={{ __html: icon.svgChildren }} />
        </svg>
      </Icon>
    );
  }
  return sideIcon;
};
