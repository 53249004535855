import { GENERIC_ANALYTICS_EVENTS } from '../../analyticsGenericEvents';
import { EVENT_ASSIST, EVENT_CATEGORY } from '../events';

export const CATEGORY_PAGE_EVENTS = {
  CATEGORY_PAGE_BACK_BUTTON_CLICK: (category: string) => GENERIC_ANALYTICS_EVENTS.CLICK_EVENT({
    category: EVENT_CATEGORY,
    label: category,
    name: 'back_to_summary',
  }),
  CATEGORY_PAGE_CAMERA_VIEW_LOADED: (kind: string) => GENERIC_ANALYTICS_EVENTS.SECTION_LOAD_EVENT({
    category: EVENT_CATEGORY,
    label: kind,
    name: 'camera_photo_input',
  }),
  CATEGORY_PAGE_DONE_BUTTON_CLICK: (category: string) => GENERIC_ANALYTICS_EVENTS.CLICK_EVENT({
    category: EVENT_CATEGORY,
    label: category,
    name: 'section_done',
  }),
  CATEGORY_PAGE_IMAGE_ASSIST_CORRECT: (aiLabel: string, aiUrl: string) => GENERIC_ANALYTICS_EVENTS.CTA_CLICK({
    button_label: 'My photo is correct',
    category: EVENT_ASSIST,
    label: aiLabel,
    name: 'Photo is correct',
    url: aiUrl,
  }),
  CATEGORY_PAGE_IMAGE_ASSIST_LOAD: (aiLabel: string) => GENERIC_ANALYTICS_EVENTS.SECTION_LOAD_EVENT({
    category: EVENT_ASSIST,
    label: aiLabel,
    name: 'Warning',
  }),
  CATEGORY_PAGE_IMAGE_ASSIST_POPUP_CLOSED: (aiLabel: string) => GENERIC_ANALYTICS_EVENTS.CLICK_EVENT({
    category: EVENT_ASSIST,
    label: aiLabel,
    name: 'Popup closed',
  }),
  CATEGORY_PAGE_IMAGE_ASSIST_POPUP_LOAD: (aiLabel: string) => GENERIC_ANALYTICS_EVENTS.POPUP_LOAD_EVENT({
    category: EVENT_ASSIST,
    label: aiLabel,
    name: 'Warning popup',
  }),
  CATEGORY_PAGE_IMAGE_ASSIST_RETAKE: (aiLabel: string, aiUrl: string) => GENERIC_ANALYTICS_EVENTS.CTA_CLICK({
    button_label: 'Retake',
    category: EVENT_ASSIST,
    label: aiLabel,
    name: 'Photo retake',
    url: aiUrl,
  }),
  CATEGORY_PAGE_LOAD: (category: string) => GENERIC_ANALYTICS_EVENTS.SECTION_LOAD_EVENT({
    category: EVENT_CATEGORY,
    label: category,
    name: 'photos_section',
  }),
  CATEGORY_PAGE_PHOTO_TAKEN_IN_CAMERA_VIEW: (kind: string) => GENERIC_ANALYTICS_EVENTS.CLICK_EVENT({
    category: EVENT_CATEGORY,
    label: kind,
    name: 'photo_taken',
  }),
  CATEGORY_PAGE_PHOTO_TIP_BUTTON_CLICK: (category: string) => GENERIC_ANALYTICS_EVENTS.CLICK_EVENT({
    category: EVENT_CATEGORY,
    label: category,
    name: 'photo_tips',
  }),
  CATEGORY_PAGE_PHOTO_TIP_MESSAGE_CARD_CLICK: (category: string) => GENERIC_ANALYTICS_EVENTS.CLICK_EVENT({
    category: EVENT_CATEGORY,
    name: `${category} category photo tip message card`,
  }),
  CATEGORY_PAGE_PHOTOS_BLOCK_CLICK: (kind: string) => GENERIC_ANALYTICS_EVENTS.CLICK_EVENT({
    category: EVENT_CATEGORY,
    label: kind,
    name: 'photo_input',
  }),
} as const;
