import { useCallback, useContext, useEffect, useRef, useState } from 'react';

import { helpers } from '@motorway/mw-enquiry-states/static';

import { Context } from '../shared/context/context';

import { ContextValueTypes } from './Types/contextTypes';
import { getHeaderHeight } from './helpers';

export function usePrevious <T>(value: T): T | undefined {
  const ref = useRef<T>();
  useEffect(() => {
    ref.current = value;
  }, [value]);
  return ref.current;
}

export const useLockRootScroll = (withoutUnmount = false) => {
  const lockRootScroll = () => {
    const root = document.getElementById('root');
    if (root) {
      root.style.position = 'fixed';
    }
  };

  const looseRootScroll = () => {
    const root = document.getElementById('root');
    if (root) {
      root.style.position = '';
    }
  };

  useEffect(() => {
    lockRootScroll();

    return () => {
      if (!withoutUnmount) {
        looseRootScroll();
      }
    };
  }, [withoutUnmount]);

  useEffect(() => {
    window.scrollTo(0, 0);

    return () => {
      if (!withoutUnmount) {
        window.scrollTo(0, getHeaderHeight() ?? 0);
      }
    };
  }, [withoutUnmount]);
};

export const useToggleCssProperty = (property: string, value: string) => {
  useEffect(() => {
    const root = document.documentElement;
    const originalValue = getComputedStyle(root).getPropertyValue(property);
    root.style.setProperty(property, value);

    return () => {
      root.style.setProperty(property, originalValue);
    };
  }, [property, value]);
};

export const useToggleHeadDisplay = (value = 'none') => {
  useToggleCssProperty('--header-display', value);
};

export const useCallFuncWithLoading = (
  func: <T>(args: T) => void | Promise<void>,
  onSuccess: () => void,
  onError: (e: unknown) => void,
) => {
  const [loading, setLoading] = useState(false);

  const callFunction = useCallback(async (args) => {
    setLoading(true);

    try {
      await func(args);
      onSuccess?.();
    } catch (e: unknown) {
      onError?.(e);
    } finally {
      setLoading(false);
    }
  }, [func, onError, onSuccess]);

  return {
    callFunction,
    loading,
  };
};

export const useIsStatusRestricted = () => {
  const context = useContext(Context) as ContextValueTypes;
  const { isStatusRestricted } = helpers;

  const { offer: { state: { slug } } } = context;

  return isStatusRestricted(slug);
};

export const useFeatureFlags = () => {
  const { featureFlags } = useContext(Context) as ContextValueTypes;

  return featureFlags || {};
};

export const isOnLine = () =>
  (typeof navigator !== 'undefined' && typeof navigator.onLine === 'boolean'
    ? navigator.onLine
    : true);

export const useNavigatorOnLine = () => {
  const [status, setStatus] = useState(isOnLine());

  const handleOnline = () => setStatus(true);
  const handleOffline = () => setStatus(false);

  useEffect(() => {
    window.addEventListener('online', handleOnline);
    window.addEventListener('offline', handleOffline);

    return () => {
      window.removeEventListener('online', handleOnline);
      window.removeEventListener('offline', handleOffline);
    };
  }, []);

  return status;
};
