import React, { useContext, useEffect } from 'react';
import { Navigate } from 'react-router';

import type { ContextValueTypes } from '../../../utilities/Types/contextTypes';
import type { PhotosType } from '../../../utilities/Types/photo.types';
import type { CategoryType } from '../../../utilities/Types/vehiclePhotosCategories.types';
import { DamageKindType } from '../../../utilities/Types/vehiclePhotosCategories.types';
import { VEHICLE_DAMAGE_KIND_ORDER, VEHICLE_PHOTOS_CATEGORY_KEY } from '../../../utilities/vehiclePhotosCategories';
import { Context } from '../../context/context';
import { usePhotosContext } from '../../context/photos';
import LandscapeWarning from '../LandscapeWarning/LandscapeWarning';
import Overlay from '../Overlay/Overlay';
import { DAMAGE_PANEL_PATH } from '../PhotosHub/PhotosHub.helpers';
import { ScrollDownHint } from '../ScrollDownHint/ScrollDownHint';

export type CameraPageProps = {
  category: string;
};

const isSafariAndVersionAbove = (version: number) => {
  const { userAgent } = navigator;
  if (/iPad/i.exec(userAgent) || /iPhone/i.exec(userAgent)) {
    const raw = /Version\/(\d+)\.\d/i.exec(userAgent);
    const v = raw?.[0]?.split('/');
    return parseFloat(v?.[1] ?? '') >= version;
  }

  return false;
};

const CameraPage = ({ category }: CameraPageProps) => {
  const {
    createVideo, isLandscape, scrollDownHintSeen, setParentState,
    showDamageLocationOverlay, videoInfo: { imageCategories },
  } = useContext(Context) as ContextValueTypes;
  // TODO: Replace with photosContext type when usePhotosContext converted to TS
  const { sortedPhotos }: { sortedPhotos: Record<string, PhotosType> } = usePhotosContext();

  useEffect(() => {
    setParentState({
      isVideoVisible: true,
    });

    return () => {
      setParentState({
        isVideoVisible: false,
        scrollDownHintSeen: false,
      });
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (isSafariAndVersionAbove(17.4)) {
      // iOS 17.4 and above - black screen issues
      // https://discussions.apple.com/thread/255562956?sortBy=best
      // https://forums.developer.apple.com/forums/thread/749054
      // trigger MediaStream
      createVideo(true);
    }
  }, []);

  let categoryImages = sortedPhotos[category];
  let currentCategory = imageCategories[category];

  if (VEHICLE_DAMAGE_KIND_ORDER.includes(category as DamageKindType)) {
    currentCategory = imageCategories[VEHICLE_PHOTOS_CATEGORY_KEY.DAMAGE]
      .filter(({ kind }: { kind: string }) => kind === category);
    categoryImages = sortedPhotos[VEHICLE_PHOTOS_CATEGORY_KEY.DAMAGE];
  }

  const showDamageOverlay = showDamageLocationOverlay
    && currentCategory.some(({ kind }: { kind: string }) => kind === showDamageLocationOverlay.kind);

  if (showDamageOverlay) {
    return <Navigate to={`../${DAMAGE_PANEL_PATH}`} />;
  }

  return (
    <>
      {!isLandscape && <LandscapeWarning />}
      {!scrollDownHintSeen && isLandscape && (
        <ScrollDownHint
          close={(status: boolean) => {
            setParentState({ scrollDownHintSeen: status });
            window.scrollTo(0, 200);
          }}
        />
      )}
      <Overlay
        currentCategory={currentCategory as CategoryType[]}
        {...{
          categoryImages,
        }}
      />
    </>
  );
};

export default CameraPage;
